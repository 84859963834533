var _paq = _paq || [];
(function() {
  if (window.apScriptInserted) {
    return
  }
  _paq.push(['clientToken', 'P%2bsIjEMd6oQ%3d'])
  var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0]
  g.type = 'text/javascript'
  g.async = true
  g.defer = true
  g.src = 'https://prod.benchmarkemail.com/tracker.bundle.js'
  s.parentNode.insertBefore(g, s)
  window.apScriptInserted = true;
})();
